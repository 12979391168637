@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Inter" -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-containerr{
  max-width: 2000px;
  margin: 0 auto;
}
.code {
  font-family: "Crimson Pro", serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}
html {
  scroll-behavior: smooth;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.32);
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #797979;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0B3B84;
}

.aadharContainer,
.itrContainer,
.capitalGainTax,
.salaryIncome,
.section80,
.tds,
.panContainer {
  width: 80%;
  margin: 0 auto;
}

@media (max-width:1440px) and (min-width:1024px){
.aadharContainer,
.itrContainer,
.capitalGainTax,
.salaryIncome,
.section80,
.tds,
.panContainer {
  width: 95%;
  margin: 0 auto;
}
}

@media  (max-width:769px) and (min-width:640px){
.aadharContainer,
.itrContainer,
.capitalGainTax,
.salaryIncome,
.section80,
.tds,
.panContainer{
    width: 95%;
  }
  .contentBox{
    width: 100% !important;
  }
}

@media (max-width:450px) {

  .aadharContainer,
  .itrContainer,
  .capitalGainTax,
  .salaryIncome,
  .section80,
  .tds,
  .panContainer {
    width: 90%;

  }

  .aadharContainer ul {
    /* list-style: disc !important; */
    margin-left: 10px !important;
  }
}


.aadharContainer ul {
  /* list-style: disc !important; */
  margin-left: 50px;
}

.aadharContainer ol {
  list-style: decimal !important;
  margin-left: 50px;
}

.contentBox {
  padding: 10px;
  border: 1px solid #cecece;
  border-top: 3px solid #0F52BA;
  width: 80%;
  border-radius: 5px;
  box-shadow: 0 0 15px #eaeaea;
}

@media (max-width:450px) {
  .contentBox {
    width: 100%;
  }
}

.contentBox h3 {
  margin: 15px;
}

.contentBox li {
  cursor: pointer;
}

.aadharContainer li svg {
  width: 30px;
  float: left;
}

.aadharContainer li p {
  width: calc(100% - 30px);
}
.panContainer li svg {
  width: 30px;
  float: left;
}

.panContainer li p {
  width: calc(100% - 30px);
}
.itrContainer li svg {
  width: 30px;
  float: left;
}

.itrContainer li p {
  width: calc(100% - 30px);
}
.itrRefund li svg {
  width: 30px;
  float: left;
}

.itrRefund li p {
  width: calc(100% - 30px);
}
.tds li svg {
  width: 30px;
  float: left;
}

.tds li p {
  width: calc(100% - 30px);
}
.salaryIncome li svg {
  width: 30px;
  float: left;
}

.salaryIncome li p {
  width: calc(100% - 30px);
}
.capitalGainTax li svg {
  width: 30px;
  float: left;
}

.capitalGainTax li p {
  width: calc(100% - 30px);
}
.aadhar-pan-link-faq-icon svg{
  width: 60px;
}
.aadhar-pan-link-faq-icon p{
  width: calc(100%-60px);
}
.section80 li svg {
  width: 30px;
  float: left;
}
.section80 li p{
  width: calc(100% - 30px);
}
.section80 li{
  align-items: baseline !important;
}

.aadharContainer li,
.itrContainer li,
.capitalGainTax li,
.itrRefund li,
.salaryIncome li,
.section80 li,
.tds li,
.panContainer li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
  font-size: 16px;
}

.nestedList {
  align-items: flex-start !important;
}

#benefitsOfAadhar p {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 85%;
  right: -60%;
  margin-right: 2px;
  background-color: #f9f9f9;
  width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navLink:hover .dropdown-content {
  display: block;
}

.dropdown-content.guide {
  width: 290px;
  margin-right: -100px;
  margin-top: 7%;
  padding-top: 10px;
}

.income-tex_guide {
  padding: 0 0px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  border-right: solid 1px #d0cece;
}

/* .hero-main-part{
  padding-top: 128px !important;
} */
.main-header-animetion{
  margin: 0 auto;
  width: 75%;
}
.navbar-glass {
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.11);
  left: 12%;
  z-index: 1200;
}

.dropdown-content span {
  color: black;
  padding: 10px;
  text-decoration: none;
  display: block;
  text-align: left;
  border-bottom: 1px solid rgb(252, 240, 240);
}

.dropdown-content span:hover {
  background-color: #0F52BA;
  color: white;
}

.product:hover .dropdown-content {
  display: block;
}

.guide:hover .dropdown-content {
  display: block;
}

.dropdown-content:hover .dropdown-content {
  display: block;
}

.income-tex_guide:hover .dropdown-content {
  display: block;
}

.video::-webkit-media-controls {
  display: none;
}

.card-1:hover {
  box-shadow: 0 0 15px #c5d3da;
  border-color: #dfe9ef;
  background: #eef4f7;
}

.card-2:hover {
  box-shadow: 0 0 15px #b4d1c3;
  border-color: #b7dcca;
  background: #ceecde;
}

.card-3:hover {
  border-color: #3d506c;
  background: #384a65;
  box-shadow: 0 0 15px #c0cfbe;
}

.card-4:hover {
  border-color: #a9d6bf;
  background: #c0e7d4;
  box-shadow: 0 0 15px #c0cfbe;
}

.card-5:hover {
  border-color: #b9dbb3;
  background: #c6e4c1;
  box-shadow: 0 0 15px #c0cfbe;
}

.card-6:hover {
  border-color: #c6e5c8;
  background: #dbf0dd;
  box-shadow: 0 0 15px #c0d3c2;
}

.card-1 img,
.card-2 img,
.card-3 .content,
.card-4 .content,
.card-5 img,
.card-6 img {
  transition: transform 0.5s ease;
}

.card-1:hover img,
.card-2:hover img,
.card-5:hover img,
.card-6:hover img {
  transform: translateY(-10px);
}

.card-3:hover .content,
.card-4:hover .content {
  transform: translateY(-10px);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* .technologies img {
  filter: gray;
  -webkit-filter: grayscale(1);
} */
.hero-pricing{
  margin: 0 auto !important;
}
.income-tax-head{
  margin: 0 auto !important;
}
.salary-house-property-plan{
  /* width: 25%; */
  background-color: #e9e9e95f;
   box-shadow: 0px 0px 2px 0px #0000001F;
  box-shadow: 0px 2px 4px 0px #00000024;
  padding: 40px 30px;
  border-radius: 12px;
}
.bussiness-professional-plan{
  /* width: 25%; */
  background-color: #e9e9e95f;
   box-shadow: 0px 0px 2px 0px #0000001F;
  box-shadow: 0px 2px 4px 0px #00000024;
  padding: 40px 30px;
  border-radius: 12px;
}
.capital-gain-plan{
  /* width: 25%; */
  background-color: #e9e9e95f;
   box-shadow: 0px 0px 2px 0px #0000001F;
  box-shadow: 0px 2px 4px 0px #00000024;
  padding: 40px 30px;
  border-radius: 12px;
}
.taxsaver-pro-plan{
  /* width: 25%; */
  background-color: #e9e9e95f;
   box-shadow: 0px 0px 2px 0px #0000001F;
  box-shadow: 0px 2px 4px 0px #00000024;
  padding: 40px 30px;
  border-radius: 12px;
}
.taxsaver-advance-plan{
  /* width: 25%; */
  background-color: #e9e9e95f;
   box-shadow: 0px 0px 2px 0px #0000001F;
  box-shadow: 0px 2px 4px 0px #00000024;
  padding: 40px 30px;
  border-radius: 12px;
}
.future-options-plan{
  background-color: #e9e9e95f;
   box-shadow: 0px 0px 2px 0px #0000001F;
  box-shadow: 0px 2px 4px 0px #00000024;
  padding: 40px 30px;
  border-radius: 12px;
}
.crypto-currency-plan{
  background-color: #e9e9e95f;
   box-shadow: 0px 0px 2px 0px #0000001F;
  box-shadow: 0px 2px 4px 0px #00000024;
  padding: 40px 30px;
  border-radius: 12px;
}
.advance-tax-calculate-plan{
  background-color: #e9e9e95f;
   box-shadow: 0px 0px 2px 0px #0000001F;
  box-shadow: 0px 2px 4px 0px #00000024;
  padding: 40px 30px;
  border-radius: 12px;
}
.gst-palns{
  background-color: #e9e9e95f;
   box-shadow: 0px 0px 2px 0px #0000001F;
  box-shadow: 0px 2px 4px 0px #00000024;
  padding: 40px 30px;
  border-radius: 12px;
}
.notice-palns{
  background-color: #e9e9e95f;
   box-shadow: 0px 0px 2px 0px #0000001F;
  padding: 40px 30px;
  border-radius: 12px;
}
.nri-resident-plan{
  background-color: #e9e9e95f;
    box-shadow: 0px 2px 4px 0px #00000024;
  padding: 40px 30px;
  border-radius: 12px;
}
.pricing-plan-child{
  width: 90%;
  margin: 0 auto;
}
.pricing-plan-child-tax-planning{
  width: 100%;
  margin: 0 auto;
}
.itr-filling-price-list{
  display: grid;
  grid-template-columns: 25% 25% 25%;
  gap: 20px;
  justify-content: center;
  margin-top: 50px;
}
.itr-filling-price-list-tax-planning{
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  gap: 20px;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}
.itr-filling-price-list-GST{
  display: grid;
  grid-template-columns: 25% 25% 25%;
  /* justify-content: space-evenly; */
  gap: 20px;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}
.itr-filling-price-list-notice-plan{
  display: grid;
  grid-template-columns: 25% 25% 25%;
  /* justify-content: space-evenly; */
  gap: 20px;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}
.itr-filling-price-list-2{
  display: grid;
  grid-template-columns: 25% 25% 25%;
  justify-content: space-evenly;
  margin-top: 50px;
}
.watsapp-us-part button{
  /* position: absolute;
  bottom: 10px; */
  margin-top: 30px;
}
.main-pricing-part li svg {
  width: 30px;
  float: left;
}

.main-pricing-part li p {
  width: calc(100% - 30px);
}
@media screen and (max-width: 1023px) {
  .dropdown-content {
    position: absolute;
    top: 120%;
    left: 0%;
    max-width: 20%;
  }

  .dropdown-content.guide {
    margin-left: 0px;
    margin-top: 0%;
    max-width: 100%;
  }

  .income-tex_guide {
    width: 50%;
  }

  .gst_guide {
    width: 50%;
  }
}
.faq-part-main-part{
  position: sticky !important;
  top: 0px !important;
}
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
@media (max-width:600px){
  .tabs{
    display: grid !important;
    grid-template-columns: auto auto !important;
    gap: 5px;
    /* justify-content: center; */
  }
}
@media (min-width:1024px) and (max-width:1279px){
  .itr-filling-price-list {
    grid-template-columns: 30% 30% 30%;
  }
  .itr-filling-price-list-tax-planning{
    grid-template-columns: 30% 30% 30%;
  }
  .itr-filling-price-list-GST{
    grid-template-columns: 30% 30% 30%;
  }
  .itr-filling-price-list-notice-plan{
    grid-template-columns: 30% 30% 30%;
  }
}
@media screen and (max-width: 1023px) {
  .navbar-glass {
    display: none;
  }
}
.container {
  box-shadow: 0 2px 6px 0 rgba(#000, 0.1);
  background: #fff;
  width: 320px;
}

.body {
  border-top: 1px solid #e6e6e6;
  padding: 8px;
  font-size: 14px;
}

.tabBar {
  display: flex;
}

.arrow {
  background: none;
  border: none;
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
}

.innerContainer {
  position: relative;
  flex: 1;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10px;
    z-index: 1;
    pointer-events: none;
  }

  &:before {
    left: 0;
    background-image: linear-gradient(to left, rgba(#fff, 0), #fff);
  }

  &:after {
    right: 0;
    background-image: linear-gradient(to right, rgba(#fff, 0), #fff);
  }
}
.watsapp-now-btn button{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.basic-tax-planer-main{
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}
.itemContainer {
  display: flex;
  flex-wrap: nowrap;
  transition: transform 0.3s ease;
}

.tabItem {
  flex-shrink: 0;
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
  cursor: pointer;
  color: #999;
  font-size: 12px;
  user-select: none;

  &:hover,
  &.isActive {
    color: #000;
  }
}
.advantages-part{
  border-radius: 8px;
  border-bottom-right-radius: 60px
}
.let-get-start button{
  /* width: 90%; */
  display: block !important ;
  margin-left: auto !important ;
  margin-right: auto !important;
}
.huf-priceing-plan{
  box-shadow: 0px 0px 2px 0px #0000001F;
  box-shadow: 0px 2px 4px 0px #00000024;
  padding: 20px;
  border-radius: 12px;
  
}

@tailwind base;
@tailwind components;
@tailwind utilities;