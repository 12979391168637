.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.App {
  text-align: center;
  margin: auto;
}
.geeks {
  color: green;
}

.tabs-container {
  width: 100%;
  margin: 20px auto;
}

.tabs {
  /* display: grid;
  grid-template-columns: auto auto auto; */
  margin-top: 25px;
}

.tab {
  text-align: center;
  cursor: pointer;
  padding: 10px 30px;
  /* margin-right: 10px; */
  border: 1px solid #ddd;
  border-bottom: none;
  border: 1px solid black;
  color: black;
  border-radius: 4px;
  max-width: 180px;
}

.tab.active {
  background-color: #005ae1;
  color: white;
  border: 1px solid rgb(57, 57, 57);
}

.tab-content {
  border: 1px solid #ddd;
  /* padding: 10px; */
  width: 100% !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
